import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/mswsn/projects/vcs-childhood-elearning-q2-2020/src/components/layout.tsx";
import { Navigation } from "../components/navigation";
import { Quiz } from "../components/quiz/quiz";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Kunskapstest`}</h1>
    <Quiz mdxType="Quiz" />
    <Navigation previous={{
      chapter: "Kapitel 3",
      title: "Hur blir jag en viktig vuxen?",
      link: "kapitel-3"
    }} next={{
      chapter: "Kapitel 5",
      title: "Avslutning",
      link: "kapitel-5"
    }} mdxType="Navigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      